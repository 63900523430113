import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { GlobleService } from '../../service/globle.service';
import { PaymentMethodsService } from '../../service/payment-methods.service';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'app-top-up-number',
	templateUrl: './top-up-number.component.html',
	styleUrls: ['./top-up-number.component.scss']
})
export class TopUpNumberComponent implements OnInit {
	frmTopUp: FormGroup;
	frmTopUpPin: FormGroup;
	submitted = false;
	submittedPin = false;
	phoneNumber: string;
	defaultPhoneNumber: string;
	amount: string;
	remark: string;
	errMsg: string;
	result: string;
	paymentType: string;
	paymentTypeUrl: string;

	phonePattern = /^\d+$/;
	amountPattern = false;
	oldAmount: string;

	promotion: any;
	topUpBody: any = {};

	rootUrl: string;
	phone: string;
	profile: any = {};
	language: string;
	paymentMethods: any[];
	public keywords;
	constructor(
		private adminService: AdminService,
		private router: Router,
		private api: GlobleService,
		private paymentMethod: PaymentMethodsService
	) { }

	ngOnInit() {
		this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
		this.language = localStorage.getItem('language');
		this.amount = localStorage.getItem('amount');
		this.phoneNumber = localStorage.getItem('phoneNumber');
		this.defaultPhoneNumber = localStorage.getItem('phone');
		this.phone = localStorage.getItem('main-phone');

		if (this.amount) {
			this.topUp();
			$('#dollar-' + this.amount).addClass('option-amount-active');
			this.oldAmount = this.amount;
		}

		this.frmTopUp = new FormGroup({
			phoneNumber: new FormControl(this.defaultPhoneNumber, [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			amount: new FormControl(this.amount, [Validators.required, Validators.max(100)])
		});

		this.frmTopUpPin = new FormGroup({
			phoneNumber: new FormControl(this.defaultPhoneNumber, [
				Validators.required,
				Validators.pattern(this.phonePattern),
				Validators.minLength(9),
				Validators.maxLength(15)
			]),
			pin: new FormControl('', Validators.required)
		});

		this.paymentMethod.getPaymentMethods().subscribe((res) => {
			this.paymentMethods = (res as any)
					.filter((r) => r.name !== 'aba')
					.map((r) => {
							if (r.name === 'wing') {
									// r.paymentUrl = `${environment.redirectUrl}/${r.name}`;
									r.paymentUrl = `${this.api.apiUrl}/v2/payment-methods/wing-token`;
							} else {
									r.paymentUrl = `${this.api.apiUrl}/${r.name === 'aba-card' ? 'aba' : r.name}/form`;
							}

							return r;
					});
	});
	}

	setAmount(amount) {
		this.frmTopUp.controls['amount'].setValue(amount);
		this.amountPattern = false;
		if (amount) {
			if (this.oldAmount) {
				$('#dollar-' + this.oldAmount).removeClass('option-amount-active');
			}
			$('#dollar-' + amount).addClass('option-amount-active');
		}
		this.oldAmount = String(amount);
	}

	removeActive() {
		$('#dollar-' + this.oldAmount).removeClass('option-amount-active');
	}

	/**
	 * Prevent translate
	 * @param keywords
	 */
	preventTranslate(keywords) {
		return this.keywords && this.keywords[keywords]
			? this.keywords[keywords][this.language]
			: keywords;
	}

	/**
	 * Control validate form pin less
	 */
	get f() {
		return this.frmTopUp.controls;
	}

	/**
	 * Control validate form pin code
	 */
	get p() {
		return this.frmTopUpPin.controls;
	}

	paymentSelected(option = '') {
			// tslint:disable-next-line:curly
			if (option === '') return;

			this.removeDisable();
			this.paymentType = option;

			$(`.card-mpay`).addClass('card-disabled').removeClass('card-selected');
			$(`#card-${option}`).addClass('card-selected').removeClass('card-disabled');

			this.topUpBody.paymentType = option;
	}

	/**
	 * Remove disable button next on payment step
	 */
	removeDisable() {
		$('#btn-mayment-next')
			.removeClass('disabled')
			.removeAttr('disabled');
	}

	/**
	 * Get remark for payment
	 */
	getRemark() {
			this.topUpBody.amount = +this.amount;
			this.topUpBody.toppedUpBy = localStorage.getItem('phone');
			this.topUpBody.type = 'pinless';
			this.topUpBody.phoneNumber = this.phoneNumber;

			this.adminService.topUp(this.topUpBody).subscribe(
					(res) => {
							const data = res as any;

							this.remark = data.remark;

							this.topUpBody.remark = data.remark;
							this.topUpBody.totalAmount = data.amount;

							this.payment();
					},
					(err) => {
							this.errMsg = err.error.message;
					}
			);
	}

	/**
	 * Open tap pinless
	 */
	pinLess() {
		$('#progress-confirm, #progress-payment, #top-up').removeClass('d-none');
		$('#top-up-pin').addClass('d-none');
		this.errMsg = '';
	}

	/**
	 * Open tap pin code
	 */
	pinCode() {
		$('#progress-confirm, #progress-payment, #top-up').addClass('d-none');
		$('#top-up-pin').removeClass('d-none');
		this.errMsg = '';
	}

	/**
	 * Submit top up by pin code
	 */
	mainTopUpPin() {
		this.submittedPin = true;

		if (this.frmTopUpPin.invalid) {
			return;
		}

		const objVerify = {
			pin: this.frmTopUpPin.get('pin').value
		};

		this.adminService.verifyPin(objVerify).subscribe(
			res => {
				this.errMsg = '';

				this.phoneNumber = this.frmTopUpPin.get('phoneNumber').value;
				const resp: any = res;
				this.amount = resp.amount;

				const obj = {
					phoneNumber: this.frmTopUpPin.get('phoneNumber').value,
					pin: this.frmTopUpPin.get('pin').value,
					type: 'pin'
				};

				this.adminService.topUp(obj).subscribe(
					response => {
						this.errMsg = '';
						$('#top-up-pin, #success').toggleClass('d-none');
						$('#progress-success').toggleClass('active success');
					},
					error => {
						let message: any;
						message = error;
						this.errMsg = message.error.message;
					}
				);
			},
			err => {
				let message: any;
				message = err;
				this.errMsg = message.error.message;
			}
		);
	}

	/**
	 * Validation amount
	 */
	checkAmount() {
		if (this.frmTopUp.get('amount').value < 1 && this.frmTopUp.get('amount').value > 100) {
			this.amountPattern = true;
		} else {
			this.amountPattern = false;
		}
	}

	/**
	 * Submit step one
	 */
	mainTopUp() {
		this.submitted = true;
		if (this.frmTopUp.get('amount').value < 1 || this.frmTopUp.get('amount').value > 100) {
			this.amountPattern = true;
			return;
		}

		if (this.frmTopUp.invalid) {
			return;
		}

		const obj = {
			phoneNumber: String(this.frmTopUp.get('phoneNumber').value)
		};

		this.adminService.verifyPhone(obj).subscribe(
			res => {
				this.errMsg = '';
				this.phoneNumber = String(this.frmTopUp.get('phoneNumber').value);
				this.amount = this.frmTopUp.get('amount').value;
				if (this.amount > '100') {
					return;
				}
				localStorage.setItem(
					'phoneNumber',
					String(this.frmTopUp.get('phoneNumber').value)
				);
				localStorage.setItem('amount', this.frmTopUp.get('amount').value);
				this.topUp();
			},
			err => {
				let message: any;
				message = err;
				this.errMsg = message.error.message;
			}
		);
	}

	/**
	 * Step one
	 */
	topUp() {
		$('#top-up, #confirm').toggleClass('d-none');
		$('#progress-confirm').toggleClass('active success');
	}

	/**
	 * Step two
	 */
	confirm() {
		// this.payment();
		$('#confirm, #payment').toggleClass('d-none');
		$('#progress-payment').toggleClass('active success');
	}

	/**
	 * Step three
	 */
	payment() {
		if (!this.topUpBody.remark && !this.topUpBody.totalAmount) {
			console.log('No remark');
			return;
		}

		const chosenPayment = this.paymentMethods.find((p) => p.name === this.topUpBody.paymentType);

		const paymentUrl = new HttpParams()
            .set('amount', this.topUpBody.totalAmount)
            .set('remark', this.topUpBody.remark)
            .set('option', chosenPayment.option || '');
			
			if (chosenPayment.name === 'aba-card') {
				this.adminService.requestAbaCard(`${chosenPayment.paymentUrl}?${paymentUrl.toString()}`).subscribe(
						(res) => {
								this.openPayment((res as any).link);
						},
						(err) => {
								console.log(err);
						}
				);
		} else if(chosenPayment.name === 'wing') {
			this.adminService.postWingToken(`${chosenPayment.paymentUrl}`, {
				remark: this.topUpBody.remark,
				amount: this.topUpBody.amount
			}).subscribe(
				(res) => {
					this.openPayment((res as any).link);
				},
				(err) => {
						console.log(err);
				}
		);
		} else {
				this.openPayment(`${chosenPayment.paymentUrl}?${paymentUrl.toString()}`);
		}
	}

	private openPayment(link: string) {
		// tslint:disable-next-line:curly
		if (!link) return 'Link is missing';

		// set window size
		const popupWidth = 800,
				popupHeight = 700,
				popupLeft = (window.screen.width - popupWidth) / 2,
				popupTop = (window.screen.height - popupHeight) / 2;

		let popup = null;
		popup = window.open(link, '', `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`);
		if (popup.open) {
				$('#btn-mayment-next').addClass('disabled').attr('disabled', 'disabled');
		}

		const interval = setInterval(() => {
				try {
						const url_string = popup.location.href;
						const url = new URL(url_string);
						const c = url.searchParams.get('result');
						if (c) {
								clearInterval(interval);
								this.result = popup.location.search.slice(8);
								if (this.result === 'success') {
										$('#payment, #success').toggleClass('d-none');
										$('#progress-success').toggleClass('active success');
										popup.close();
										localStorage.removeItem('phoneNumber');
										localStorage.removeItem('amount');
								} else {
										popup.close();
								}
						}
				} catch (evt) {}
				if (popup.closed) {
						$('#btn-mayment-next').removeClass('disabled').removeAttr('disabled');
						clearInterval(interval);
				}
		}, 100);
}
}
